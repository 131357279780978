<template>
  <div id="z-login-background">
    <div class="left-side" :class="[{ loading }]" :style="leftSideStyle">
      <div class="left-footer">
        <template v-if="isZubut">
          <span v-if="isZubut" class="title">
            Con {{ companyName }} crea y rastrea tus envíos
          </span>
          <span class="subtitle">
            CDMX - GDL - MTY
          </span>
        </template>
        <template v-else>
          <span class="title">
            Nuevo servicio de mensajeros dedicados
          </span>
          <span class="subtitle">
            Bogotá - Medellín - Barranquilla
          </span>
        </template>
      </div>
    </div>

    <div class="right-side">
      <loading-login v-if="loading" />
      <router-view v-else />
    </div>

    <notifications group="notifications" />
  </div>
</template>

<script>
import LoadingLogin from "@/app/views/Login/LoadingLogin.vue";

export default {
  name: "InitLayout",

  components: {
    LoadingLogin
  },

  data() {
    return {
      zubutBackgroundImage: require("@/assets/img/img-login-bg.jpg"),
      zubutDashboardImage: require("@/assets/img/img-dashboard.png"),
      muBackgroundImage: require("@/assets/img/mu-img-login-bg.jpg"),
      muDashboardImage: require("@/assets/img/mu-img-dasboard.png"),
      dashboardImage: require("@/assets/img/mu-img-dasboard.png"),
      backgroundImage: require("@/assets/img/mu-img-login-bg.jpg")
    };
  },

  computed: {
    loading() {
      return !this.$store.state.region;
    },
    isZubut() {
      return this.$store.getters["isZubut"];
    },
    companyName() {
      return this.$store.getters["companyName"];
    },
    leftSideStyle() {
      if (this.dashboardImage && this.backgroundImage) {
        return {
          "background-image": ` url(${this.dashboardImage}),url('${this.backgroundImage}')`
        };
      }
      return "";
    }
  },

  watch: {
    loading: {
      immediate: true,
      handler() {
        if (this.isZubut) {
          this.backgroundImage = this.zubutBackgroundImage;
          this.dashboardImage = this.zubutDashboardImage;
        } else {
          this.backgroundImage = this.muBackgroundImage;
          this.dashboardImage = this.muDashboardImage;
        }
      }
    }
  },

  created() {
    this.$store.dispatch("getCountry");
  }
};
</script>

<style lang="scss" scoped>
#z-login-background {
  display: flex;
  height: 100vh;
  min-height: 636px;
}

.left-side {
  position: relative;
  background-repeat: no-repeat, no-repeat;
  background-size: 96%, cover;
  background-position: 0 50px, 0;
  animation: float 3s ease-in-out infinite;
  flex: 1.1 1 0;

  &.loading {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    background-color: #dddbdd;
    background-image: none !important;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0) 0
      );
      animation: shimmer 1.5s infinite;
      content: "";

      @keyframes shimmer {
        100% {
          transform: translate(100%);
        }
      }
    }
  }
}

.left-footer {
  position: absolute;
  color: $white;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 16px 0;

  .title {
    font-size: 1rem;
    font-weight: 700;
    display: block;
    margin-bottom: 12px;
  }
  .subtitle {
    font-size: 0.9rem;
  }
}

.right-side {
  flex: 0.9 1 0;
}

@keyframes float {
  0%,
  100% {
    background-position: 0 50px, 0;
  }
  50% {
    background-position: 0 62px, 0;
  }
}

@media (max-width: 990px) {
  #z-login-background {
    min-height: 530px;
  }
  .left-side {
    display: none;
  }
  .right-side {
    flex: 1;
  }
}
</style>
