<template>
  <div class="login-wrapper">
    <header>
      <slot name="header"></slot>
    </header>

    <section>
      <slot name="section"></slot>
    </section>

    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "LoginWrapper"
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  flex-direction: column;
  gap: 70px;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 100px 0 16px;
}

header {
  flex: 0.5;
}
section {
  flex: 1.5;
}

@media (max-width: 990px) {
  .login-wrapper {
    padding: 50px 0 16px;
  }
}
</style>
