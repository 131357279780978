<template>
  <login-wrapper>
    <template v-slot:header>
      <skeleton-box width="50px" height="50px" />
    </template>
    <template v-slot:section>
      <skeleton-box width="300px" height="42px" />
      <skeleton-box class="mt-5" width="100%" height="42px" />
      <skeleton-box class="mt-3" width="100%" height="42px" />
      <skeleton-box class="mt-5" width="100%" height="42px" />
    </template>
  </login-wrapper>
</template>

<script langth="ts">
import LoginWrapper from "./LoginWrapper.vue";
import SkeletonBox from "@zubut/common/src/components/SkeletonBox.vue";

export default {
  name: "LoadingLogin",

  components: {
    LoginWrapper,
    SkeletonBox
  }
};
</script>
